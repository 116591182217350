import React, { useState, useTransition } from "react";
import HeroImage from '../../../images/request-demo/hero.svg'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { PrimaryAlternativeBtn } from "components/library";
import { ArrowRight } from "react-feather";
import { Link } from "gatsby";
import LazyIframe from "components/common/lazy-iframe";

export const Hero = () => {
    const { t } = useTranslation()
    const [, startTransition] = useTransition();
    const [showVideo, setShowVideo] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const VIDEO_ID = "egjqXIg2HDA"

    return (
        <div className="flex flex-col gap-4 sm:gap-8 items-center">
            <div className="font-semibold text-3xl sm:text-5xl lg:text-6xl text-center pt-10">
                <Trans
                    components={{ br: <br /> }}
                    i18nKey={"request-demo-page.title"}
                ></Trans>
            </div>

            <div className="flex flex-col text-xl gap-1 max-w-max">
                <Link to="#request-demo-registration-form" >
                <PrimaryAlternativeBtn
                        className="ring-theme-grey"
                >
                    <div className="flex gap-2 items-center">{t("common.cta.demo")} <ArrowRight size={18} /></div>
                </PrimaryAlternativeBtn>
                </Link>
            </div>

            <div className="w-full aspect-video">
                <div style={{
                    overflow: "hidden",
                    position: "relative",
                }} className="w-full h-full">
                    {(!showVideo || !hasLoaded) && (
                        <button
                            style={{
                                height: "100%",
                                width: "100%",
                                left: 0,
                                bottom: 0,
                                right: 0,
                                top: 0,
                                border: 0,
                                cursor: "pointer",
                                position: "absolute",
                                backgroundColor: "transparent",
                                margin: 0,
                                padding: 0,
                                textDecoration: "none",
                            }}
                            onClick={() => {
                                startTransition(() => {
                                    setShowVideo(true);
                                });
                            }}
                        >
                            <div style={{
                                bottom: 0,
                                height: "100%",
                                left: 0,
                                position: "absolute",
                                right: 0,
                                top: 0,
                                width: "100%"
                            }} className="flex flex-col justify-center">
                                <img
                                    alt="Youtube Thumbnail"
                                    src={HeroImage}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }}
                                    loading="lazy"
                                />
                                <img
                                    alt="Play Video"
                                    src={"https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg"}
                                    loading="lazy"
                                    style={{
                                        height: "42px",
                                        left: "calc(50% - 30px)",
                                        position: "absolute",
                                        top: "calc(50% - 21px)",
                                        transition: "all 0.3s ease-in-out",
                                        width: "60px"
                                    }}
                                />
                            </div>
                        </button>
                    )}
                    {showVideo && (
                        <LazyIframe videoId={VIDEO_ID} setHasLoaded={setHasLoaded} />
                    )}
                </div>
            </div>
        </div>

    )
};
