import { PrimaryBtn } from 'components/library';
import { Link } from 'gatsby';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation()
  return <div className='flex flex-col md:flex-row gap-8 items-center justify-between p-9 text-white bg-blue-gradient-bg backdrop-blur rounded-2xl'>
    <div className="flex">
      <div className='text-4xl mr-6 font-medium'>
        <Trans
          components={{ br: <br /> }}
          i18nKey={"request-demo-page.banner-title"}
        ></Trans>
      </div>
    </div>
    <Link to="https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/1.+POLYMERIZE+A4%E3%83%95%E3%83%A9%E3%82%A4%E3%83%A4%E3%83%BC.pdf">
      <PrimaryBtn
        className="bg-theme-orange hover:bg-[#F57438] hover:text-white md:text-lg max-w-max py-2 px-6"
      >
        {t("common.cta.learn-more")}
      </PrimaryBtn>
    </Link>
  </div>
}

export default Banner;
