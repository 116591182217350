import React from "react"
import { graphql } from "gatsby"
import { Hero } from "components/pages/request-demo/hero"
import { Container } from "components/library"
import Article from "components/pages/request-demo/article"
import { Registration } from "components/pages/request-demo/registration"
import Banner from "components/pages/request-demo/banner"


const ProductPage = () => {
  return (
    <main className="bg-request-demo-bg-mobile sm:bg-request-demo-bg bg-no-repeat -mt-28 bg-contain sm:-mt-36 pt-28 sm:pt-36 min-h-[600px] w-full">
      <Container className="flex flex-col gap-8 sm:gap-10 pt-8 pb-20 text-white">
        <Hero />
        <article className={`flex gap-10 flex-col md:flex-row items-start justify-between text-neutral-900`}>
          <Article />
          <Registration title={"title"} />
        </article>
        <Banner />
      </Container>
    </main >
  )
}

export default ProductPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 
