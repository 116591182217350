import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const myStyles = css`
font-family: "work-sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
`


const StyledH2 = styled.h2`
        ${myStyles}
    `;

const Article = () => {
  const { t } = useTranslation();


  return (
    <article className="text-neutral-900 flex flex-col gap-[30px]">
      <StyledH2 className="text-3xl md:text-5xl">{t('request-demo-page.article-title')}</StyledH2>
      {/* <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span> */}
      <span style={{ fontSize: "22px" }}>
        <Trans
          components={{ b: <b />, p: <p className="mt-6" /> }}
          i18nKey={"request-demo-page.article-para-1"}
        ></Trans>
      </span>
      {process.env.GATSBY_LANGUAGE_SELECTED === 'en' && <span style={{ fontSize: "22px" }}>
        <Trans
          components={{ b: <b />, p: <p className="mt-6" /> }}
          i18nKey={"request-demo-page.article-para-2"}
        ></Trans>
      </span>}
    </article>
  )
}

export default Article